#root {
  min-height: 100%;
  padding-bottom: 50px;
  position: relative;
}
.container-narrow {
  margin-top: 50px;
  width: 450px !important;
}

.container-wide {
  margin-top: 30px;
}

.container-full {
  height: 100%;
}

.purple {
  color: #FF17E9FF;
}

a.purple {
  color: #FF17E9FF;
}

a.item.purple {
  color: #FF17E9FF !important;
}

.purple-link {
  color: #FF17E9FF !important;
}

.ui.black.button, .ui.black.buttons .button {
  color: #FF17E9FF !important;
}

.black {
  color: #000 !important;
}

.bold {
    font-weight: bold;
}

.magenta {
  color: #3935D8 !important;
}

.ui.button.tertiary {
  box-shadow: none !important;
  background: none;
  padding-left: 5px;
  padding-right: 5px;
}

.horizontal-scroll {
  width: 100%;
  overflow-x: auto;
  padding: 1rem 0;
}

.mr-1 {
  margin-right: 1rem;
}

@media only screen and (max-width: 600px) {
  .top-menu {
      display: none !important;
  }
}

@media only screen and (min-width: 600px) {
  .block-menu {
      display: none !important;
  }
}

.disabled-input input {
  background-color: rgba(0,0,0,.05) !important;
}

.ui.dimmer.modals .ui.loader:before {
  border-color: rgba(0,0,0,.1);
}
.ui.dimmer.modals .ui.loader:after {
  border-color: #767676 transparent transparent;
}

/*
.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
  background-color: #FF17E9FF !important;
}

.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
  background-color: #FF17E9FF !important;
}*/

.map-container {
  height: 100%;
  width: 100%;
}

#geocoder-container > .mapboxgl-ctrl-geocoder > .mapboxgl-ctrl-geocoder--input {
  padding: 0.67857143em 2.4em !important;
  font-size: 1em !important;
  border: none !important;
  outline: none !important;

  height: 32px !important;
  font-size: 12px !important;
}

.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
  /*box-shadow: none !important;*/
}

.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 0px 1px rgb(0 0 0 / 10%) !important;
  z-index: 1000 !important;
}

.ui-container-fix {
  width: 100% !important;
}

.place-map-analytics-dropdown {
  position: fixed;
  margin: 20px 0 0 20px;
  z-index: 999;
  top: 100px;
}

.place-map-analytics-dropdown-table {
  position: fixed;
  margin:  20px 0 0 20px;
  padding: 20px 20px 100px 20px;
  z-index: 999;
  height: 100%;
  background-color: white;
  width: 600px !important;
  overflow-y: scroll;
  top: 50px;
}

.place-map-analytics-dropdown-table table {
  /*width: 555px !important;*/
}

.place-map-analytics-dropdown-table th {
  font-size: 11px;
}


.suiGeocoder > .mapboxgl-ctrl-geocoder {
  width: 100%;
  max-width: 100%;
  box-shadow: none !important;
}

.suiGeocoder > .mapboxgl-ctrl-geocoder > svg {
  display: none;
}

.suiGeocoder .mapboxgl-ctrl-geocoder > input {
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif !important;
  margin: 0;
  outline: 0;
  -webkit-appearance: none !important;
  tap-highlight-color: rgba(255,255,255,0) !important;
  padding: 0.67857143em 1em !important;
  background: #fff !important;
  border: 1px solid rgba(34,36,38,.15) !important;
  color: rgba(0,0,0,.87) !important;
  border-radius: 0.28571429rem !important;
  transition: color .1s ease,border-color .1s ease !important;
  font-size: 14px !important;
  line-height: 24px !important;
  height: 38px !important;
  z-index: 1 !important;
}

.suiGeocoder.error > .mapboxgl-ctrl-geocoder > input{
  background-color: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none !important;
}
.suiGeocoder .mapboxgl-ctrl-geocoder > input::placeholder {
  color: rgba(191,191,191,.87);
}
.suiGeocoder .mapboxgl-ctrl-geocoder > input:focus::placeholder {
  color: rgba(115,115,115,.87);
}
.suiGeocoder.error > .mapboxgl-ctrl-geocoder > input::placeholder{
  color: #e7bdbc !important;
}

.suiGBCell {
  width: 130px !important;
}

#lGeocoder > .mapboxgl-ctrl-geocoder {
  position: relative;
  z-index: 9 !important;
}

#lGeocoder > .mapboxgl-ctrl-geocoder > .suggestions-wrapper {
  position: relative;
  z-index: 9 !important;
}

#aGeocoder > .mapboxgl-ctrl-geocoder > .suggestions-wrapper {
  position: relative;
  z-index: 8 !important;
}

#aGeocoder > .mapboxgl-ctrl-geocoder {
  position: relative;
  z-index: 8 !important;
}

.lc-score {
  text-align: end;
}

.lc-green{
  color: green;
}

.lc-yellow{
  color: orange;
}

.lc-red{
  color: red;
}

.lc-scores-padding {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}


.place-map-final {
  position: fixed;
  bottom:20px;
  right:20px;
  z-index: 999;
  width: 300px;
  height: 480px;
  background-color: #fff;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #e0e0e0;
}
.header-lower-text{
  font-size: 14px;
}

.location-report-text {
  font-size: 18px;
}

.location-report-value {
  font-size: 24px;
}

.mapboxgl-ctrl-geocoder {
  max-width: 100%!important;
  width: 100%!important;
}
.report-tab-container::after {
  content: "";
  clear: both;
  display: table;
}
.report-tab-container > span {
  padding: 5px 5px;
  margin: 0 15px 10px 15px;
  font-weight: 400;
  font-size: 16px;
  color: gray;
  cursor: pointer;
  display: inline-block;
}
.report-tab-container > span.active {
  border-bottom: 1px solid blue;
  color:black;
}
.report-tab-header{
  border-bottom: 1px solid blue;
  margin-bottom: 20px;
  padding-top: 15px;
  width: 100%;
}
.report-tab-link{
  margin-top: 24px !important;
  padding: 0 !important;
}
.report-tab-link a{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;

  color: #3935D8;
}
.report-tab-grid {
  padding-top: 0 !important;
}
.menu-no-border {
  border: 0 none !important;
  box-shadow: none  !important;
}

.menu-no-border .itm {
  padding-top: 24px;
}
.pb-print {
  page-break-after: always;
  width:100%;
  display: block;
  height:1px;
}
.gender-report-graph{
  width: 100%;
}
.gender-report-graph div {
  float: left;
}
.gender-report-graph div:first-child{
  background-color: #2C2C2C;
  border-radius: 10px 0 0 10px;
  height: 20px;
}
.gender-report-graph div:last-child{
  background-color: #3935D8;
  border-radius: 0 10px 10px 0;
  height: 20px;
}
.gender-report-graph::after {
  content: "";
  clear: both;
  display: table;
}
.gender-report-items{
  width: 100%;
  display: table;
  margin-top: 10px;
}
.gender-report-items div{
  display: table-cell;
  font-weight: 400;
  font-size: 15px;
}
.gender-report-items span{
  font-weight: 600;
}
.gender-report-items div:first-child{
  text-align: left;
  padding-left: 20px;
}
.gender-report-items div:last-child{
  text-align: right;
  padding-right: 20px;
}
.ethnic {
font-weight: 500;
  font-size: 20px;
  padding: 8px 0;
  display: block;
}
.report-map-box {
  width: 100%;
  height: 400px;
  border-radius: 30px;
  overflow: hidden;
}
.cu-map-box {
    width: 100%;
    height: 600px;
    border-radius: 30px;
    overflow: hidden;
}
.export-button {
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(57, 53, 216, 0.3);
  border-radius: 40px;
  align-items: center;
  padding: 10px 50px 10px 30px;
  gap: 10px;
  color: black;
  cursor: pointer;
  display: inline-block;
  border: 1px solid white;
  position: relative;
}

.export-button:hover {
  border: 1px solid #3935D8 !important;
}

.export-button:after {
  position: absolute;
  right: 20px;
  top: 50%;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  display: block;
  content: " ";

  width: 20px;
  height: 20px;

  background-image: url("/public/icons/copy-icon.png");
  background-repeat: no-repeat;
  background-size: cover;
}

h4.amenities-header > span {
  color: #3935D8;
}
.top-5-header{
  border-bottom: 1px solid #2C2C2C;
  margin: 20px 0;
}
.favg-header {
  border-bottom: 1px solid #8A95A5;
  margin: 20px 0;
}
.top-5-header span:first-child{
  float:left;
  display: block;
  padding: 5px 0;
}
.top-5-header span:last-child{
float: right;
  display: block;
  padding: 5px 0;
}
.top-5-header::after {
  content: "";
  clear: both;
  display: table;
}
.amenities-item span{
  font-weight: 600;
  font-size: 20px;
}
.amenities-item span:first-child{
  float:left;
  display: block;
  padding: 5px 0 5px 60px;
  margin-left: -60px;
  max-width: 100%;
  width: 100%;
}
.amenities-item span:last-child{
  float: right;
  display: block;
  padding: 5px 0;
}
.amenities-item::after {
  content: "";
  clear: both;
  display: table;
}
.report-top-buttons{
  width: 100%;
}
.report-top-buttons::after {
  content: "";
  clear: both;
  display: table;
}
.report-top-buttons-left{
  float:left;
  display: block;
}
.report-top-buttons-right{
  float: right;
  display: block;

  padding: 10px 30px;
  gap: 10px;

  background: #3935D8;
  box-shadow: 0px 0px 20px rgba(57, 53, 216, 0.3);
  border-radius: 40px;
  color: white;

  margin-top: 18px;
  margin-left:25px;
}

.report-top-buttons-right:hover {
  background: #2C2C2C !important;
  color: white !important;
}

.two-column-nospaces {
  width: 100%;
  max-width: 100%;
}
.two-column-nospaces div:first-child{
  float: left;
  display: block;
  width: 50%;
  padding-right:5px;
}
.two-column-nospaces div:last-child{
  float: right;
  display: block;
  width: 50%;
  padding-left:5px;
}
.two-column-nospaces::after {
  content: "";
  clear: both;
  display: table;
}
.atm_h_ico::before {
  content: url('/public/icons/h_atm.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.bank_h_ico::before {
  content: url('/public/icons/h_bank.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.college_h_ico::before {
  content: url('/public/icons/h_college.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.food_h_ico::before {
  content: url('/public/icons/h_food.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.grocery_h_ico::before {
  content: url('/public/icons/h_grocery.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.hospital_h_ico::before {
  content: url('/public/icons/h_hospital.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.mall_h_ico::before {
  content: url('/public/icons/h_mall.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.nursery_h_ico::before {
  content: url('/public/icons/h_nursery.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.parking_h_ico::before {
  content: url('/public/icons/h_parking.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.post_h_ico::before {
  content: url('/public/icons/h_post.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.school_h_ico::before {
  content: url('/public/icons/h_school.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.subway_h_ico::before {
  content: url('/public/icons/h_subway.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.supermarket_h_ico::before {
  content: url('/public/icons/h_supermarket.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.train_h_ico::before {
  content: url('/public/icons/h_train.svg');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.report-qa b{
  font-size: 20px;
}
.report-qa span{
  font-size: 18px;
  line-height: 1.8;
}
.footfall-tooltip {
  cursor: pointer;
  color: #8A95A5;
  font-size: 18px;
  margin: -5px 0 0 5px;
  position: absolute;
}
.footfall-tooltip:hover>span {
  visibility: visible;
}

.footfall-tooltip > span {
  visibility: hidden;
  width: 240px;
  background-color: rgba(211, 211, 211, 0.95);
  /* bordeR: 2px solid blue; */
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 7px;
  position: absolute;
  z-index: 1;
  font-size: 12px;
  margin-left: 10px;
  font-weight: normal;
  line-height: 2;

  /* Position the tooltip */
  position: absolute;
}
.report-date-picker{
  display: block;
}
.report-date-picker-text, .report-date-picker-dates {
  display: inline-block;
}
.report-date-picker-text {
  margin-right: 10px;
}
.report-date-picker-dates > span {
  margin: 0 10px !important;
}
.loader-screen{
  position: fixed;
  top: 0;
  lefT: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(128, 128, 128, 0.76);
  color: white;
}
.active-loader{
  overflow: hidden !important;
}
.loader-screen>div{
  height: 100%;
  width: 100%;
  /* vertical-align: middle; */
  text-align: center;
  position: relative;
  top: 48%;
  font-size: 28px;
}
.two-child-ff{
  width: 300px;
}
.two-child-ff > div:first-child{
  width: 100%;
}
.two-child-ff > div.time-intervals{
  width: 60px;
  margin-top: 15px;
}
.two-child-ff::after {
  content: "";
  clear: both;
  display: table;
}

.map-crime-popup-d {
  border-bottom: 1px solid gray;
  display: block;
  max-width: 100%;
  width: 100%;
  margin-bottom: 7px;
  margin-top: 5px;
}
.map-popup-button {
  max-width: 100%;
  width: 100%;
  padding: 6px 0;
  gap: 10px;
  background: #3935D8;
  box-shadow: 0 0 20px rgb(57 53 216 / 30%);
  border-radius: 40px;
  color: white;
  cursor:pointer;
  display: block;
  text-align: center;
}
.mapboxgl-popup-content {
  padding: 20px  10px 10px 10px !important;
  border-radius: 12px !important;
  width: 280px !important;
}
.crime-details {

}
.hidden {
  display: none;
}
.hide-crime-details {
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}
.mapboxgl-popup-close-button {
  padding: 7px 7px 5px 5px !important;
}

.map-legend-container {
  position: absolute;
  right: 30px;
  bottom: 80px;
}

.map-loader{
  padding: 10px;
  background-color: transparent;
  border-radius: 5px;
  min-width: 220px;
  max-width: 220px;
  width: 220px;
  margin-bottom:20px;
  height: 100px;
  position: relative;
}

.map-legend{
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  min-width: 220px;
  max-width: 220px;
  width: 220px;
  box-shadow: 0 0 3px;
}
.legend-gradient-container{
  height: 30px;
  width: 100%;
  display: table;
}
.legend-gradient-container > div {
  display: table-cell;
  opacity: 0.7;
}
.legend-gradient-items{
  width: 100%;
  display: table;
}
.legend-gradient-items div{
  display: table-cell;
  font-weight: 250;
  font-size: 12px;
}
.legend-gradient-items div:first-child{
  text-align: left;
}
.legend-gradient-items div:last-child{
  text-align: right;
}
.legend-info {
  font-size: 16px;
  font-weight: 400;
  padding-right: 5px;
  margin-bottom: 10px;
}
.legend-dblclck::before {
  content: url('/public/icons/dblclick.png');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.legend-rclck::before {
  content: url('/public/icons/rclick.png');
  display: block;
  float: left;
  margin: 0 5px 0 0;
}
.legend-checkbox-items {
  display: table;
  margin-bottom: 10px;
}
.legend-checkbox-items > div {
  vertical-align: middle;
  display: table-cell;
  font-size: 16px;
  font-weight: 400;
}
.legend-checkbox-items > div:first-child {
  padding-right: 15px;
}
.legend-checkbox-items > div:last-child {

}
.legend-commercial{
  width: 22px;
  height: 22px;
  padding-top: 15px;
  display: block;
}
.legend-commercial::before {
  content: url('/public/icons/commercial-point.png');
  display: block;
  float: left;
}
.legend-office::before {
  content: url('/public/icons/office-point.png');
  display: block;
  float: left;
}
.feedback-promo {
  background-color: #3935D8;
  margin: 20px 0;
  text-align: center;
  color: white;
  padding: 15px;

  font-size: 18px;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  line-height: 1.28571429em;

  display: table;
  width: 100%;
}

.feedback-promo > a {
  font-size: 16px;
  padding: 10px 30px;
  gap: 10px;
  background: white;
  border-radius: 40px;
  color: #3935D8;
  display: inline-block;
  margin-right: 30px;
}

.feedback-promo > i {
  text-align: right;
  display: table-cell;
  cursor: pointer;
}
.footfall-map-header {
  margin: 7px 15px 0 0;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
}
.footfall-map-info {
  margin-bottom: 7px;
  display: inline-block;
  font-size: 16px;
  border-bottom: 1px solid #4183c4;
}
.map-legend-mini {
  position: absolute;
  right: 30px;
  bottom: 40px;
  padding: 5px;
  background-color: white;
  border-radius: 5px;
  /* min-width: 220px; */
  /* max-width: 220px; */
  width: 160px;
  box-shadow: 0 0 3px;
}
.map-legend-mini > .legend-gradient-container {
  height: 20px !important;
}
.map-legend-mini .legend-gradient-items div {
  font-size: 10px !important;
}

.wrapper-class {
  border: 1px solid #ccc;
  padding: 5px;
}

.editor-class {
  width: 100% !important;
  height:85vh !important
}


/*----------------------------*/
.rdw-embedded-modal-link-input {
  width: 88% !important;
  height: 35px !important;
  margin: 10px 0 !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 2px !important;
  font-size: 15px !important;
  padding: 0 5px !important;
}
.rdw-embedded-modal-size-input {
  width: 80% !important;
  height: 20px !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 2px !important;
  font-size: 12px !important;
}
.rdw-image-modal-url-input {
  width: 90% !important;
  height: 35px !important;
  margin: 15px 0 12px !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 2px !important;
  font-size: 15px !important;
  padding: 0 5px !important;
}

.rdw-image-modal-size-input {
  width: 40% !important;
  height: 20px !important;
  border: 1px solid #F1F1F1 !important;
  border-radius: 2px !important;
  font-size: 12px !important;
}

.rdw-link-modal-input {
  margin-top: 5px !important;
  border-radius: 2px !important;
  border: 1px solid #F1F1F1 !important;
  height: 25px !important;
  margin-bottom: 15px !important;
  padding: 0 5px !important;
}
.rdw-link-modal-input:focus {
  outline: none !important;
}

.rdw-editor-toolbar {
  padding: 6px 5px 0 !important;
  border-radius: 2px !important;
  border: 1px solid #F1F1F1 !important;
  display: flex !important;
  justify-content: flex-start !important;
  background: white !important;
  flex-wrap: wrap !important;
  font-size: 15px !important;
  margin-bottom: 5px !important;
  user-select: none !important;
}

.rdw-image-modal-upload-option {
  width: 100% !important;
  color: gray !important;
  cursor: pointer !important;
  display: flex !important;
  border: none !important;
  font-size: 12px !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #f1f1f1 !important;
  outline: 2px dashed gray !important;
  outline-offset: -10px !important;
  margin: 10px 0 !important;
  padding: 9px 0 !important;
}

.rdw-center-aligned-block * {
  text-align: center !important;
}

.rdw-left-aligned-block * {
  text-align: left !important;
}

.rdw-right-aligned-block * {
  text-align: right !important;
}

.rdw-justify-aligned-block * {
  text-align: justify !important;
}
.appbar-white{
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 30px 0 0;
  background-color: transparent;
  display: table;
  vertical-align: middle;
  min-height: 55px;
  min-width: 1024px;

  z-index: 1000;
}
.appbar-white-fixed {
  position: fixed !important;
  top: 0;
  left: 0;
}
.appbar-white-ico {
  display: table-cell;
  width: 150px;
  vertical-align: middle;
  padding: 11px 0 11px 30px;
  background-color: white;
}
.appbar-white-ico a {
  font-weight: 700;
  font-size: 24px;
  color: #3935D8 !important;
  font-family: 'Open Sans';
  text-decoration: none !important;

}
.appbar-white-links {
  padding: 11px 10px 11px 0;
  display: table-cell;
  vertical-align: middle;
  background-color: white;
  border-top-right-radius: 80px 80px;
  border-bottom-right-radius: 80px 80px;
}
.appbar-white-links > a {
  font-weight: 400;
  font-size: 20px;
  margin-left: 50px;
  cursor: pointer;
  color: #2C2C2C;
  display: inline-block;
  padding: 2px 0;
}
.appbar-white-links > a.active{
  color: #3935D8 !important;
  border-bottom: 1px solid #3935D8;
}
.appbar-white-controls {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding-left: 20px;
  width: 1px;
  max-height: 55px;
  height: 55px;

  position: relative;
  white-space: nowrap;
}
.appbar-white-controls-profile {
  width: 55px;
  height: 55px;
  display: block;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(57, 53, 216, 0.2);
  border-radius: 30px;
  cursor: pointer;
  position: relative;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.appbar-white-controls-profile:hover {
  box-shadow: 0px 0px 25px rgba(57, 53, 216, 0.6);
}
.appbar-white-controls-profile::before {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  content: url("/public/icons/profile.png");
}
.appbar-white-spacer {
  display: table-cell;
}
.appbar-white-controls-profile-popup {
  position: absolute;
  width: 300px;
  padding: 30px;
  top: 70px;
  right: 0;

  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(57, 53, 216, 0.3);
  border-radius: 20px;
  text-align: left;
  z-index: 1;
}
.appbar-profile {
  position: relative;
  border-bottom: 1px solid #2C2C2C;
  text-align: left;
  word-break: break-all;
}
.appbar-profile > div:first-child{
  width: 85px;
  float:left;
}
.appbar-profile > div:last-child{
  width: 100%;
  padding-left: 85px;
  margin-left: -85px;
  float:left;
  text-align: left;

  white-space: break-spaces;
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.appbar-profile-name {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;

  display: block;
  line-height: 1.6;
}
.appbar-profile-email {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;

  display: block;
  line-height: 1.5;
}
.profile-button {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;

  border-radius: 40px;
  padding: 15px 30px 15px 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(57, 53, 216, 0.3);
  display: block;
  color: #3935D8;
  cursor: pointer;
  margin: 20px 0;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.profile-button:hover {
  box-shadow: 0px 0px 25px rgba(57, 53, 216, 0.6);
}
.profile-nav {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;

  margin-top: 20px;
  display: block;
  color: black;
}
.profile-nav:hover{
  text-decoration: none;
  color: black;
}

/*GETPLACE BUTTONS*/
.gb-white{
  border: 1px solid white;
}
.gb-white:hover{
  border: 1px solid #3935D8;
}

.gb-magenta{
  background: #3935D8 !important;
  color: white !important;
}
.gb-magenta:hover{
  background: #2C2C2C !important;
  color: white !important;
}
/*GETPLACE BUTTONS END*/


.appbar-new-report{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  background: #3935D8;

  border-radius: 40px;
  padding: 15px 30px;

  color: white;
}
.appbar-new-report:hover{
  background: #2C2C2C !important;
  color: white !important;
}

#navLogo {
  z-index: 2;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-decoration: none;
  color: #3935d8;
  z-index: 2;
}

.report-container .mapboxgl-ctrl-geocoder.mapboxgl-ctrl {
  height: 50px !important;
  line-height: 50px !important;
  font-size: 18px !important;
}

.report-container .mapboxgl-ctrl-geocoder--suggestion {
  line-height: 20px !important;
}


.report-container #geocoder-container > .mapboxgl-ctrl-geocoder > .mapboxgl-ctrl-geocoder--input {
  font-size: 18px !important;
}

@media screen and (min-width: 640px) {
  .report-container .mapboxgl-ctrl-geocoder--icon-search {
      position: absolute;
      top: 13px !important;
      left: 12px !important;
      width: 23px !important;
      height: 23px !important;
  }

  .report-container .mapboxgl-ctrl-geocoder--icon-close {
    width: 20px !important;
    height: 20px !important;
    margin-top: 8px !important;
    margin-right: 3px !important;
  }
}

.report-container .mapboxgl-ctrl-geocoder--suggestion-address {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.report-container .search-button {
  background: #3935d8;
  border-radius: 40px !important;
  color: #fff;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 25px !important;
}
.map-admin-fix .appbar-nomargin{
  position: absolute;
  width: 100%;
  z-index: 1000;
}
.appbar-nomargin{
  margin:0 !important;
}
.map-admin-fix{
  /*padding-top:44px;*/
}
.appbar-report-counter{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

}
.appbar-buy-fullsub{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  background: #3935D8;

  border-radius: 40px;
  padding: 15px 30px;
}
.location-report-ctypes > .dropdown {
  height: 50px !important;
  font-size: 18px !important;
  margin-top: 4px;
}
.welcome-screen{
  overflow-y: scroll;

  position: fixed;
  top: 0;
  lefT: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(128, 128, 128, 0.76);
  color: white;
}
.welcome-popup{

  background-color: #FFFFFF;
  border-radius: 10px;
  width: 900px;
  padding: 60px;
  text-align: center;
  position: relative;

  top: 50%;
  transform: translateY(-50%);

  margin-left: 50%;
  left: -450px;
}
.welcome-popup > h1{
  font-family: 'Krona One';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 140%;
  /* or 56px */

  text-align: center;
  color: black;
  margin-bottom: 30px;
}
.welcome-popup > .welcome-text-block {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  /* or 28px */

  text-align: center;
  margin-bottom: 30px;
  color: black;
}
.welcome-button {
  background: #3935d8;
  border-radius: 40px !important;
  color: #fff;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 25px !important;
}
.welcome-close-button{

  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  color: black;

  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 36px;
}
.welcome-note {
  margin-top: 10px;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: black;
}
.liq-subway-icon:before{
  content: ' ';
  background-image: url('/public/icons/h_subway.svg');
  background-size: 12px 12px;
  width: 12px;
  height: 12px;
  display: inline-block;
  left: 0;
  top: 2px;
  margin-right: 5px;
}
.liq-train-icon:before{
  content: ' ';
  background-image: url('/public/icons/h_train.svg');
  background-size: 12px 12px;
  width: 12px;
  height: 12px;
  display: inline-block;
  left: 0;
  top: 2px;
  margin-right: 5px;
}
.footfall-banner {
  background: url("/public/report_banner.jpg") no-repeat;
  background-size: contain;
  background-position-y: 10px;
}
.demographics-banner{
  width: 100%;
  height: 300px;

  background: url("/public/report_banner.jpg") no-repeat center;
  background-size: contain;
  background-position-y: 10px;
}
.location-report-nearby-table{
  font-size: 16px !important;
}
.lr-consultation {
  width: 100%;
  padding: 30px;

  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(57, 53, 216, 0.2);
  border-radius: 30px;

  margin-bottom: 50px;
}
.lr-photo{
  display: block;
  height: 482px;
  width: 357px;
  float: left;

  border-radius: 30px;

  background: url("/public/consultation_photo.jpg");
  background-position-x: center;

  position: relative;
}
.lr-photo-title-container {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 10px;
}
.lr-photo-title{
  background-color: white;
  width: 100%;
  color: #3935D8;
  border-radius: 20px;

  padding: 10px;
  text-align: center;

  font-family: 'Open Sans';
  font-style: normal;
  font-size: 20px;
  line-height: 27px;
}
.lr-consultation::after {
  content: "";
  clear: both;
  display: table;
}
.lr-text-container{
  padding: 20px 0 0 387px;

  float: left;
  width: 100%;
  margin-left: -357px;
}
.lr-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;

  margin-bottom: 20px;
}

.lr-consultation-list {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px !important;
  line-height: 27px;

  margin-bottom: 50px !important;
}

.lr-button {
  padding: 15px 30px;
  gap: 10px;

  text-align: center;

  background: #FFC81D;
  border-radius: 40px;
  margin-bottom: 70px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #3935D8;

  cursor: pointer;
  display: inline-block;
}
.lr-understand{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
}
.lr-understand-text{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 27px;

  /* Blue */

  color: #3935D8;
}
.lr-lnkdn-ico{
  width: 38px;
  height: 38px;
  display: inline-block;
  margin-right: 20px;
  background: url(/public/lr_ico2.png);
}
.lr-email-ico{
  width: 38px;
  height: 38px;
  display: inline-block;
  margin-right: 20px;
  background: url(/public/lr_ico1.png);
}
.lr-alert{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;

  margin-bottom: 30px;
  color: #3935D8;
}
.location-report-day-selection-flex {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
}
.location-report-day-selection-flex span.itm {
  color: #808080;
  padding: 10px;
  cursor: pointer;
}
.location-report-day-selection-flex span.active {
  color: #000;
  border-bottom: 1px solid #3935D8;
}
.location-report-day-selection{
  display: table;
}
.location-report-day-selection h2 {
  display: table-cell;
  padding-right: 30px;
}
.search-label {
  height: 40px;
  display: table-cell;
  vertical-align: middle;
}

.price-pulse-container {
  width: 100%;
  display: block;
  position: relative;
}
.price-pulse-header {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  gap: 0;

  background: linear-gradient(transparent 30%, #8886E8 40%) no-repeat;
}
.price-pulse-header > div {
  cursor: pointer;

  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background-color: #FFFFFF;

  box-shadow: 0px 0px 8px 0px #8886E8;
  padding: 22px 30px 10px 30px;

  font-size: 24px;
  font-weight: 700;

  user-select: none;

}
.price-pulse-header > div:not(:last-child) {
  border-bottom-right-radius: 22px!important;
}
.price-pulse-header > div:last-child {
  border-bottom-left-radius: 22px!important;
}
.price-pulse-header > div.active {
  background-color: #8886E8;
  box-shadow: none;
  color: white;
}
.price-pulse-menu {
  padding: 22px 30px;
  background-color: #8886E8;
}
.price-pulse-ico {
  padding-left: 38px;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  height: 28px;
  margin-bottom: 5px;
}
.price-pulse-header  p {
  margin-top: 5px;
}
.price-pulse-ico:before{
  width: 28px;
  height: 28px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  color: #3935D8;
}
div.active  .price-pulse-ico:before {
  background-color: #8886E8;
}
.price-pulse-ico-compare:before{
 content: url("/public/icons/price-pulse-compare.svg");
}
div.active  .price-pulse-ico-compare:before{
  content: url("/public/icons/price-pulse-compare-active.svg");
}
.price-pulse-ico-research:before{
  content: url("/public/icons/price-pulse-research.svg");
}
div.active  .price-pulse-ico-research:before{
  content: url("/public/icons/price-pulse-research-active.svg");
}
.price-pulse-t-descr {
  font-size: 16px;
}
.price-pulse-menu label {
  display: block;
  color: white;
  font-weight: 600;
  margin-bottom: 5px;
}
.price-pulse-menu .dropdown{
  width: 100%;
}
.price-pulse-container .colored {
  background-color: #F5F5FD;
}
.price-pulse-container .colored td {
  border-top: 2px solid #9795EB !important;
}
.price-pulse-overall {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}
.price-pulse-overall > div p:first-child {
  font-size: 18px;
  margin-bottom: 10px!important;
}
.price-pulse-overall > div p:last-child {
  font-size: 16px;
}
.price-pulse-margin {
  display: flex;
  justify-content: space-between;
  color: #3935D8;
}
.price-pulse-no-report {
  display: block;
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;

  width: 100%;

  margin-top: 20px;
}
.price-pulse-show-points {
  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;

  margin: 40px 0 100px 0;
  display: block;

  width: 100%;

  color: #3935D8;

  cursor: pointer;
}
.price-pulse-map-mark:before{
  content: url("/public/icons/map_mark.png");
}
.price-pulse-report-mark:before{
  content: url("/public/icons/report_mark.png");
}
.price-pulse-mark{
  display: inline-block;
  width: 15px;
  height: 20px;
}
.price-pulse-mark:before{
  width: 15px;
  height: 20px;
}
.pp-link{
  display: inline-block;
  margin-right: 20px;
}
.price-pulse-refresh-button{
  background-color: #3935D8;
  border-radius: 40px;

  padding: 5px 25px;
  display: inline-block;
  cursor: pointer;
}
.price-pulse-refresh-button:hover {
  background-color: black;
}
.price-pulse-refresh{
  display: block;
  width: 32px;
  height: 33px;
}
.price-pulse-refresh:before{
  content: url("/public/icons/charm_refresh.png");

  width: 32px;
  height: 33px;
  display: block;
}
.price-pulse-menu .dropdown {
  min-height: 43px!important;
  font-size: 15px !important;
}
.pp-selected-items > div {
  display: inline-block;
  padding: 10px 45px 10px 25px;
  border-radius: 40px;
  background-color: white;

  margin: 0 10px 5px 0;

  font-family: Lato;
  font-size: 18px;
  font-weight: 400;
  line-height: 19px;
  position: relative;
}
.pp-selected-items > div > i {
  position: absolute;
  width: 20px;
  height: 20px;
  content: '\x2715';
  right: 15px;
  top: 9px;
  z-index: 2;
  font-style: normal;
  font-size: 20px;
  cursor: pointer;
}
.ps-inner-table {
  height: 300px;
   max-height: 300px;
}
.ps-info-table {
  height: 100%;
}
.ps-info-table td, .ps-inner-table td {
  font-size: 16px;
}
.ps-10{
  width: 10%;
}
.ps-15{
  width: 15%;
}
.ps-20{
  width: 20%;
}
.ps-data-table  table.ps-inner-table {
  height: 100% !important;
  width: 100% !important;
}
.ps-inner-table td {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.ps-inner-table td:first-child{
  padding-left: 10px !important;
}
.ps-data-table .colored td {
  border-top: 2px solid #9795EB !important;
}
.ps-info-table td {
  vertical-align: top;
}
.ps-min{
  color: #E76D83;
}
.ps-max{
  color: #36827F;
}
.ps-margin {
  display: flex;
  justify-content: space-between;
}

.ps-data-table > tbody > tr:first-child > td, .ps-info-table > tbody > tr:first-child > td {
  border-top: 2px solid #9795EB !important;
}

.ps-data-table > tbody > tr:last-child > td, .ps-info-table > tbody > tr:last-child > td {
  border-bottom: 2px solid #9795EB !important;
}
.ps-data-table > tbody > tr > td {
  padding: 0 !important;
}
.pp-sortable{
  cursor: pointer;
  position: relative;
  padding-right: 18px;
  display: inline-block;
}

.pp-sortable:after {
  background-size: 12px 9px;
  width: 12px;
  height: 9px;
  position: absolute;
  right: 0;
  top: 5px;
  content:"";
}

.pp-sortable-off:after{
  background-image: url("/public/icons/sort-off.png");
}

.pp-sortable.asc:after{
  transform: rotate(180deg);
}

.pp-sortable-on:after {
  background-image: url("/public/icons/sort-on.png") !important;
}

.poi-map-popup {
  max-width: none !important;
}

.rating-block{
  display: inline-flex;
  align-items: flex-start;
  margin-bottom: 10px;
}
.rating-block > span.rating {
  font-size: 16px;
  color: gray;
  padding-right: 5px;
}
.rating-block > span.reviews {
  font-size: 16px;
  color: gray;
  padding-left: 5px;
}
.rating-block > span.stars {
  height: 18px;
  width: 18px;
  display: inline-block;

  background-size: 18px 18px;
}
.rating-block > span.full {
  background-image: url("/public/icons/star.png") !important;
}
.rating-block > span.half {
  background-image: url("/public/icons/star-half.png") !important;
}
.rating-block > span.empty {
  background-image: url("/public/icons/star-empty.png") !important;
}
.poi-map-types {
  display: inline-block;

  font-size: 16px;
  color: gray;
  padding-right: 5px;

  margin-bottom: 10px;
}
.poi-map-address {
  padding-left: 20px;

  font-size: 14px;
  color: black;
  position: relative;

  margin-bottom: 10px;
}
.poi-map-address:before {
  position: absolute;
  display: inline;
  left: 0;
  width: 15px;
  height: 20px;
  background-size: 15px 20px;
  background-image: url("/public/icons/map_mark.png");
  content: "";

  top: 50%;
  margin-top: -10px;
}
.poi-map-header {
  margin-bottom: 5px !important;
}
.poi-map-subheader {
  color: gray;
}
.poi-map-hey-buy{
  margin-top: 25px;
  padding: 20px;

  border-top:2px solid gray;
}
.poi-map-hey-buy ul {
  padding-left: 20px !important;
}
.poi-map-hey-buy .poi-map-header {
  text-align: center;
}
.poi-map-button{
  text-align: center;
  margin-top: 15px;
}
.poi-map-button .appbar-buy-fullsub{
  display: inline-block;
}
.poi-map-beta {
  background-color: #404BB1;
  padding: 5px 15px;
  border-radius: 5px;
  color: white;
  margin-left: 5px;
}
.place-map-analytics-dropdown-table label {
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
}
.poi-map-buy-radio{
  cursor: pointer;
}
.poi-map-lock {
  display: block;
  height: 20px;
  width: 20px;
  background-image: url("/public/icons/lock.png");
  background-size: 20px 20px;

  cursor: pointer !important;
}

.poi-map-buy-overlay {
  position: fixed;
  top: 0;
  lefT: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(65, 75, 178, 0.76);
  color: white;
}

.poi-map-buy-overlay > div {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color: black;
  background-color: white;
  padding: 50px 70px;
  border-radius: 20px;
}

.poi-map-buy-overlay .close-block {
  content: "\x2715";
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  height: 20px;
  width: 20px;
  position: absolute;
  display: inline-block;

  top: 8px;
  right: 8px;
}
.poi-map-buy-overlay h2 {
  margin: 0 0 20px 0 !important;
  text-align: center;
}
.poi-map-buy-overlay ul {
  padding-left: 10px;
  margin-bottom: 20px;
}
.poi-map-buy-overlay li {
  font-size: 16px;
  line-height: 1.7;
  letter-spacing: 0.5px;
}
.poi-map-buy-overlay .appbar-buy-fullsub {
  display: inline-block;
  border-radius: 5px !important;

  background-color: rgba(65, 75, 178, 1) !important;
}

.gray-menu {
  color: gray;
  opacity: 0.7;
}
.welcome-screen .disabled {
  opacity: 0.9 !important;
}
.report-table-placeholder td{
  padding: 0!important;
}
.report-table-placeholder td > div.pcline {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, minmax(0, 1fr));

}

.report-table-placeholder td{
  position: relative;
}
.report-table-placeholder td .banner {
  position: absolute;
  top: 0;
  background-color: white;
  border-radius: 20px;
  padding: 40px;
  text-align: center;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
}
.report-table-placeholder td > div.pcline:not(:first-child):not(:last-child){
  margin: 2px 0;
}
.report-table-placeholder td > div.pcline:first-child{
  margin-top: 20px;
}
.report-table-placeholder td .pcol {
  background-color: #F6F8FA;
  padding: 20px;
}
.report-table-placeholder .pcol:before{
  content: "";
  display: block;
  width: 100%;
  height: 20px;
  top:0;
  left:0;
  background-color: #EDF2F5;
}
.report-table-placeholder .pcol1 {
  grid-column: 1/6;
}
.report-table-placeholder .pcol2 {
  grid-column: 6/7;
}
.report-table-placeholder .pcol3 {
  grid-column: 7/8;
}
.report-table-placeholder .pcol4 {
  grid-column: 8/9;
}
.report-table-placeholder .pcol5 {
  grid-column: 9/11;
}
.doc-icon {
  display: inline-block;
  height: 90px;
  width: 90px;
  border-radius: 45px;

  background-color: #EDF2F5;
  margin-bottom: 20px;
}
.doc-icon:before{
  display: block;
  content: "";
  background-image: url("/public/icons/document.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 90px;
  width: 90px;
}
.subscriptions {
  margin: 0 auto;
  min-width: 1000px;
  max-width: 1350px;
}
.subscriptions h1 {
  display: block;
  text-align: center;
  font-size: 48px;
  padding: 20px 0;
}
.sub-block{
  height: 100%;
  padding: 64px 44px 72px;
  background-color: #F4F6F8;
}
.sub-block-header {
  display: block;
  padding-left: 30px;
  position: relative;

  font-size: 22px;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 1.375rem
}
.sub-block-header:before{
  content: "";
  display: inline-block;
  width: 20px;
  height: 22px;

  background-size: 20px 22px;
  position: absolute;
  left: 0;
  top: 0;
}
.sub-block-header.rep1:before {
  background-image: url("/public/icons/icon-report-1.png");
}
.sub-block-header.rep2:before {
  background-image: url("/public/icons/icon-report-2.png");
}
.sub-block-header.rep3:before {
  background-image: url("/public/icons/icon-report-3.png");
}
.sub-block-perfect {
  font-size: 20px;
  margin-bottom: 40px;
}
.sub-block-pricing {
  font-size: 36px;
  margin-bottom: 36px;
}
.sub-block-pricing .price {
  font-size: 52px;
}
.sub-block-pricing .sub {
  font-size: 14px;
  margin-right: 8px;
  line-height: 100%;
}
.sub-block-buy {
  display: block;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 1px solid #3935D8;
  text-align: center;

  color: #3935D8;
  background-color: white;

  user-select: none;
  padding: 15px 0;
  font-size: 22px;

  cursor: pointer;
}
.sub-block-buy.disabled {
  opacity: 0.5;
  cursor: default;
}
.sub-block-buy.active {
  color: white;
  background-color: #3935D8;
}
.sub-block-buy.active:hover {
  background-color: #4f46e5;
}
.sub-block-buy:hover {
  background-color: #3935D8;
  color: white;
}
.sub-block-buy.disabled:hover {
  color: #3935D8;
  background-color: white;
}
.sub-block-includes {

}
.sub-block-includes span {
  display: block;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 24px;
}
.sub-block-includes ul {
  padding: 0;
}
.sub-block-includes li {
  padding-left: 15px;
  position: relative;
  list-style-type: none;
  margin-bottom: 20px;
  font-size: 16px;
}
.sub-block-includes li:before {
  position: absolute;
  left: 0;
  display: block;
  content: "\2713";
}
.sub-block-help{
  padding: 20px 0;
  text-align: center;
  font-size: 24px;
  color: gray;
  font-weight: 500;
}

.sub-block-help a {
  font-weight: bold;
  color: #3935D8;
}
.sub-block-help a:hover{
  color: #4f46e5;
}
#pano_container{
  width: 100%;
  max-height: 500px;


}
#pano {
  width: 100%;
  aspect-ratio: 2.1;
}
#pano-map {
  width: 100%;
  aspect-ratio: 2.1;
}
#pano_not_found {
  width: 100%;

  text-align: center;
  display: block;
  padding: 50px;

  font-size: 45px;
  font-weight: bold;
}
.delivery-map-box {
  height: 700px;
  width: 100%;
  margin-bottom: 100px;
}
.orders-email-filter{
  margin-right: 30px;
}
.login-popup-overlay {
  overflow-y: scroll;
  position: fixed;
  top: 0;
  lefT: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(128, 128, 128, 0.76);
  color: white;
}
.login-popup-container {
  display: flex;
  width: 720px;
  margin: 0 auto;
  top: 50%;

  background: #A89CFC;
  border-radius: 5px;
  position: relative;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: Open Sans;

}
.login-popup-container > div:last-child {
  width: 320px;
  background: transparent;
  border-radius: 5px;

  padding: 40px;
  color: #271288;

  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: space-between;
}
.login-popup-container > div:last-child .upper-block {
  color: #271288;

  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
}
.login-popup-body {
  width: 400px;
  background: white;
  border-radius: 5px;

  padding: 40px;
  color: black;
  position: relative;
}
.login-popup-body h2 {
  font-family: Lato;
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;

  padding: 0 !important;
  margin: 0 0 30px 0 !important;
}
.login-popup-block {
  text-align: left;
  margin-bottom: 20px;
}
.login-popup-block label {
  display: block;

  color: #2C2C2C;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;

  margin-bottom: 10px;
}
.login-popup-block input{
  display: block;
  border: 1px solid #3935D8;
  border-radius: 50px;

  padding: 18px 32px;
  margin: 0 0 0 0;
  width: 100%;
}
.login-popup-block input:focus {
  border: 1px solid #040091;
  outline: 1px solid #040091;
}
.login-popup-block .gtxt{
  color: #ABABAB;
  margin: 5px 0 2px 0;

  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
}
.login-popup-button {
  display: block;
  width: 100%;
  padding: 15px 30px;

  border: none;
  background: #FFC81D;

  text-align: center;
  color: #3935D8;

  border-radius: 40px;
  cursor: pointer;

  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;

  user-select: none;

  margin-bottom: 20px;
}
.login-popup-button:hover {
  outline: 1px solid #b08600;
  color: #3935D8;
  text-decoration: none;
}
.login-popup-button:active {
  background: #f6be10;
}

.login-popup-grtxt {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 20px;
}

.login-popup-grtxt p{
  margin: 0 0 2px 0 !important;
  padding: 0 !important;
  color: #ABABAB;
}
.login-popup-grtxt a{
  margin: 0 !important;
  padding: 0 !important;
  text-decoration: none;
  color: #3935D8;
}
.login-popup-grtxt a:hover{
  text-decoration: none;
  color: #3935D8;
}
.login-popup-signin span {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
}
.login-popup-signin a {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;

  margin: 0 !important;
  padding: 0 !important;
  text-decoration: none;
  color: #3935D8;

  cursor: pointer;
}

.login-popup-button-white {
  display: block;
  width: 100%;
  padding: 15px 30px;

  border: none;
  box-shadow: 0px 0px 20px 0px rgba(57, 53, 216, 0.3);
  background: white;

  text-align: center;
  color: #3935D8;

  border-radius: 40px;
  cursor: pointer;

  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;

  user-select: none;

  margin-bottom: 20px;
}
.login-popup-button-white:hover {
  outline: 1px solid #3935D8;
  color: #3935D8;
  text-decoration: none;
}
.login-popup-button-white:active {
  background: #e1e3ef;
}
.login-popup-close-block {
  content: "\x2715";
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  height: 20px;
  width: 20px;
  position: absolute;
  display: inline-block;

  top: 8px;
  right: 8px;
}
.login-popup-close-button{

  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  color: black;

  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 22px;
}
.bottom-block-photo {
  margin-bottom: 20px;
  height: 48px;
}
.bottom-block-hdr {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: black;
}
.bottom-block-dscr{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;

  color: rgba(39, 18, 136, 1);
}
.login-popup-block input.error {
  border: 1px solid red;
}
.login-popup-block input.error:focus {
  outline: 1px solid red !important;
}
.login-popup-err{
  padding: 20px;
  border: 1px solid red;
  border-radius: 5px;
  margin: 5px 0 15px 0;

  font-size: 14px;
  color: #c02929;
}
.disabled-link {
  pointer-events: none;
}
.report-login-popup {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 400px;
  z-index: 99;
  box-shadow: 0px 0px 20px 0px rgba(57, 53, 216, 0.3);
}
.location-report-subh {
  font-family: Lato;

  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px !important;
}
.DFA-h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 31px;

  margin: 0;
}
.DFA-table.main .DFA-h2{
  font-size: 26px;
}
.DFA-subh2{
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
}
.DFA-h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
}
.DFA-h2-subh{
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
}
.DFA-h2-unh{
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  margin: 0 0 5px 0;
}
.DFA-table{
  display: table;
  width: 100%;
  margin: 7px 0;
}
.DFA-table-cell {
  display: table-cell;
  vertical-align: middle;
}
.DFA-table-hdr {
  width: 150px;
}
.DFA-table-40 {
  width: 40%;
}
.DFA-table-60 {
  width: 60%;
}
.DFA-table-row {
  display: table-row;
}
.DFA-table-col {
  display: table-cell;
  vertical-align: middle;
  max-height: 30px;
  height: 32px;
  font-size: 20px;
}
.DFA-table-col.itm {
  width: 200px;
  padding: 0 40px 0 0;
}
.DFA-table-col .flex-data {
  display: flex;
  justify-content: space-between;
}
.DFA-line {
  border-radius: 3px;
  height: 8px;
}
.DFA-table.main .flex-data{
  font-weight: 600;
}
.DFA-table.main .DFA-line{
  height: 10px !important;
}
.DFA-table.bottom-line {
  border-bottom: 1px solid rgba(39, 18, 136, 1);
}
.location-report-h2-large {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
}
.monthly-analytics {
  font-family: Lato;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;

  color: #3935D8;
  margin-bottom: 30px;
}
.monthly-analytics-container{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}
.monthly-analytics-itm{
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  color: #808080;
  padding: 5px;

  cursor: pointer;
}
.monthly-analytics-itm.active{
  border-bottom: 1px solid #3935D8;
}
.location-report-main{
  width: 100%;
  padding-bottom: 50px;
  position: relative;

  display: flex;
  flex-direction: column;
}
.location-report-footer{
display: table;
  position: absolute;

  bottom: 0;
  height: 50px;

  background: black;
  color: white;
  text-align: center;
  width: 100%;
}
.location-report-footer > div {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.map-contu-u{
  height: 100vh;
  width: 100%;
}
.map-admin-fix.map-contu-u .mapboxgl-map{
  padding-bottom: 50px;
}
.location-report-nav-container {
  padding: 40px 0;
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: center;
}
.location-report-nav-button {
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(57, 53, 216, 0.3);
  border-radius: 40px;
  align-items: center;
  padding: 10px 30px 10px 30px;
  gap: 10px;
  color: black;
  cursor: pointer;
  display: inline-block;
  border: 1px solid white;
  position: relative;
}
.location-report-nav-button.next {
  padding-right: 40px !important;
}
.location-report-nav-button.prev {
  padding-left: 40px !important;
}
.location-report-nav-button.next:after {
  position: absolute;
  right: 20px;
  top: 50%;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  display: block;
  content: " ";

  width: 8px;
  height: 12px;

  background-image: url("/public/icons/arr-r.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.location-report-nav-button.prev:before {
  position: absolute;
  left: 20px;
  top: 50%;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  display: block;
  content: " ";

  width: 8px;
  height: 12px;

  background-image: url("/public/icons/arr-l.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.DFA-diff div{
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  padding-left: 20px;

  position: relative;
}
.DFA-diff p {
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}
.DFA-diff div.plus:before {
  position: absolute;
  left: 5px;
  top: 50%;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  display: block;
  content: " ";

  width: 11px;
  height: 8px;

  background-image: url("/public/icons/dfa-up.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.DFA-diff div.minus:before {
  position: absolute;
  left: 5px;
  top: 50%;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  display: block;
  content: " ";

  width: 11px;
  height: 8px;

  background-image: url("/public/icons/dfa-down.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.location-report-grid > .grid > .row{
  padding: 40px 0 !important;
}

/* SEMANTIC FIX
@media only screen and (min-width: 1600px) {
  .ui.container {
    width: 1300px;
  }
}*/
.RE-hdr{
  display: flex;
  justify-content: space-between;
}
.RE-hdr > div {
  display: flex;
  align-items: center;
  gap: 20px;

  font-size: 17px;
}
.RE-table {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.RE-table > div {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 50%;
}
.RE-table .DFA-diff > div{
  font-size: 15px;
}
.RE-table .DFA-diff > p{
  font-size: 12px;
}
.RE-table td {
  font-size: 16px;
}
.RE-flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.w150p {
  width: 150px;
}
.ss-container {
  display: grid;

  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 30px;

  width: 100%;
  align-items: start;

  padding: 0 !important;
}
.ss-card {
  box-sizing: border-box;
  padding: 30px;
  border-radius: 30px;
  box-shadow: 0px 0px 20px 0px rgba(57, 53, 216, 0.20);

  user-select: none !important;
}
.ss-card:hover {
  box-shadow: 0px 0px 25px 0px rgba(57, 53, 216, 0.40);
}
.ss-header {
  font-family: Lato;
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  margin-bottom: 30px;
}
.ss-ins {
  width: 100%;
  text-align: right;
  padding-top: 10px;
}
.ss-ins > span {
  padding-right: 50px;

  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;

  color: #3935D8;

  cursor: pointer;
  position: relative;
}
.ss-ins > span:after {
  position: absolute;
  right: 10px;
  top: 50%;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  display: block;
  content: " ";

  width: 30px;
  height: 17px;

  background-image: url("/public/icons/ss-arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.ss-items-grid {
  display: grid;

  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;
  width: 100%;
  margin-bottom: 20px;
}
.ss-items-100 {
  width: 100%;

  margin-bottom: 20px;
}

.ss-items-100 .main, .ss-items-grid .main {
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  margin-bottom: 4px;
}
.ss-items-100 .props, .ss-items-grid .props {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

}
.pano-small {
  display: flex;
  gap: 30px;
}
.pano-small > div {
  height: 270px;
  overflow: hidden;
  border-radius: 10px;
}
.snapshot-pano {
  width: 100%;
  padding: 30px !important;
  background: white;

  box-shadow: 0px 0px 20px 0px rgba(57, 53, 216, 0.20);
  border-radius: 30px;
}
.location-report-ss-header {
  font-family: Lato;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;

  margin-bottom: 30px;
  color: #3935D8;
}
.snapshot-pano h2 {
  margin-bottom: 35px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.snapshot-pano h2 > span {
  position: relative;
  text-align: right;

  padding: 14px 30px 14px 56px;
  box-shadow: 0px 0px 20px 0px rgba(57, 53, 216, 0.20);
  border-radius: 40px;

  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;

  cursor: pointer;
}
.snapshot-pano h2 > span:hover {
  box-shadow: 0px 0px 25px 0px rgba(57, 53, 216, 0.40);
}
.snapshot-pano h2 > span:before {
  position: absolute;
  left: 25px;
  top: 50%;

  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  display: block;
  content: " ";

  width: 22px;
  height: 15px;

  background-image: url("/public/icons/car.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.pano-small > #pano_not_found {
  height: auto !important;
}
.pano-small > #pano_not_found > span {
  font-size: 30px !important;
}
.ss-avg-footfall {
  width: 100%;

  padding: 30px !important;
  background: white;

  box-shadow: 0px 0px 20px 0px rgba(57, 53, 216, 0.20);
  border-radius: 30px;
}
.demographics-container-2{
    width: 100%;
    display: grid;
    gap: 20px;

    grid-template-columns: repeat(2, minmax(0, 1fr));
}
.demographics-container-3{
    width: 100%;
    display: grid;
    gap: 20px;

    grid-template-columns: repeat(3, minmax(0, 1fr));
}
.demographics-container-3 > .item, .demographics-container-2 > .item {
    border-radius: 10px;
    padding: 25px;

    background: white;
    box-shadow: 0px 0px 20px 0px rgba(57, 53, 216, 0.20);
    border-radius: 30px;
}
.demographics-chart{
    width: 100%;
    background-color: transparent;
}
.demographics-chart > div {
    display: block;
    height: 100%;
    background-color: #c4c2f3;
    color: black;
}
.demographics-chart.small > div {
    padding: 5px 0;
    font-size: 12px;
}
.demographics-chart.medium > div {
    padding: 10px 0;
    font-size: 16px;
}
.demographics-chart.large > div {
    padding: 15px 0;
    font-size: 20px;
}
.demographics-mid-value {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.1;
    display: block;
}
.demographics-single-value {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.2;
    display: block;
}
.demographics-charts-container {
    font-size: 1.1em;
}
